import React from 'react';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';
import allUserAvatar from '../img/icon-All.png';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { useEffect, useState } from 'react';

import plusAvatar from '../img/icon-Plus.png';
import GroupImageLeader from '../img/icon-Group-leader.png';
import OrgImageAdmin from '../img/icon-Org-leader.png';
import OrgImageMember from '../img/icon-Org-member.png';
import GroupImageMember from '../img/icon-Group-member-regular.png';

import i18n from '../i18n';

const prebuiltAvatars = [plusAvatar, allUserAvatar];

export const AvatarImage = (props: any) => {
  const { userId } = props;
  const [imageUrl, setImageUrl] = useState(defaultAvatar);
  const className = props.className ?? 'avatar-img';

  useEffect(() => {
    if (userId < 0) {
      const idx = 0 - userId - 1;
      const avatarUrl = prebuiltAvatars[idx];
      setImageUrl(avatarUrl);
    } else {
      _dataProvider.getAvatar(userId).then((avatarUrl) => setImageUrl(avatarUrl));
    }
  }, []);

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null; // Prevent looping
    e.currentTarget.src = defaultAvatar;
    //e.currentTarget.className = 'avatar-icon';
    e.currentTarget.alt = 'Default Avatar';
    //e.currentTarget.style.width = size;
    //e.currentTarget.style.height = size;
  };

  return <img src={imageUrl} alt='' className={className} onError={handleError} />;
};

export const GroupImage = (props: any) => {
  const { item, className } = props;
  if (item.isOrgGroup) {
    const imageUrl = item.isGroupLeader ? OrgImageAdmin : OrgImageMember;
    return <img src={imageUrl} className={className} />;
  }
  if (item.isOneOnOneGroup) {
    return <AvatarImage userId={item.friendUserId} className={className} />;
  }
  const imageUrl = item.isGroupLeader ? GroupImageLeader : GroupImageMember;
  return <img src={imageUrl} className={className} />;
};

export const userAvatarName = (user: IUser, showName = 1, showSetting = false, size = '60px', fontSize = 14) => {
  const fullname = user.displayName ?? user.name;
  const name = showName == 1 ? fullname.slice(0, 4) : fullname + ' ' + (showName === 3 ? user.tag ?? '' : '');

  return (
    <>
      <div className='avatar-item'>
        <div key={user.id || user.userId} className='avatar-container' style={{ width: size, height: size }}>
          <AvatarImage userId={user.id || user.userId} />
        </div>
        {!!showName && (
          <div className='avatar-name' style={{ fontSize: `${fontSize}px` }}>
            {name}
          </div>
        )}
      </div>
      {showSetting && (
        <>
          <div className='avatar-name'>
            {i18n.t('UserID')}: {user.loginId || user.uniqueId}
          </div>
        </>
      )}
    </>
  );
};
