import React, { useState, useEffect, useRef } from 'react';
import {
  DefaultButton,
  TextField,
  ITextFieldStyles,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Stack,
  Image,
  IStackTokens,
  MessageBar,
  MessageBarType,
  Text,
  mergeStyleSets
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { _dataProvider, IUser } from '../dataProvider/DataProvider';
import { getJwtObj } from '../SharedCommon/utils';
import defaultAvatar from '../img/icon-DefaultProfilePicture.png';
import Footer from './Footer';

interface IUserSettings extends IUser {
  displayLanguage: string;
  avatarUrl: string;
}

const stackTokens: IStackTokens = {
  childrenGap: 8,
  padding: 20
};

const MySettings: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [settings, setSettings] = useState<IUserSettings | null>(null);
  const [isModified, setIsModified] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    fetchUserSettings();
  }, []);

  const fetchUserSettings = async () => {
    try {
      setLoading(true);
      const jwtObj = getJwtObj();

      const displayName = await _dataProvider.getDisplayName();
      const uniqueId = _dataProvider.getMyProfile().uniqueId;

      const avatarUrl = await _dataProvider.getAvatar(jwtObj.userId);
      console.log('Fetched avatar URL:', avatarUrl); // Debug log

      setSettings({
        ...jwtObj,
        displayName: displayName,
        uniqueId: uniqueId,
        displayLanguage: i18n.language,
        avatarUrl: avatarUrl
      });

      setError(null);
    } catch (error) {
      console.error('Failed to fetch user settings:', error);
      setError(t('FailedToFetchSettings'));
      setSettings(null);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateDisplayName = async () => {
    if (!settings) {
      return;
    }

    if (settings.displayName.trim() === '') {
      setError(t('MyProfile.Display name cannot be empty'));
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Check the success of the update operation
      const success = await _dataProvider.updateUserDisplayName(settings.displayName);

      if (success) {
        setIsModified(false);
      }
    } catch (error) {
      console.error('Failed to update user name:', error);
      setError(t('MyProfile.FailedToUpdateDisplayName'));
    } finally {
      setLoading(false);
    }
  };

  const handleDisplayNameChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (settings) {
      setSettings({ ...settings, displayName: newValue || '' });
      setIsModified(newValue !== settings.displayName);
    }
  };

  const handleLanguageChange = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if (option && settings) {
      const newLanguage = option.key as string;
      setSettings({ ...settings, displayLanguage: newLanguage });
      i18n.changeLanguage(newLanguage);
    }
  };

  const handleAvatarClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && settings) {
      try {
        setLoading(true);
        await _dataProvider.uploadAvatar(file);
        // After successful upload, force refresh avatar by appending timestamp
        const newAvatarUrl = await _dataProvider.getAvatar(settings!.userId, true);
        setSettings((prevSettings) => ({
          ...prevSettings!,
          avatarUrl: newAvatarUrl
        }));
        setError(null);
      } catch (error) {
        console.error('Failed to upload avatar:', error);
        setError(t('MyProfile.FailedToUploadAvatar'));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleAvatarError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.onerror = null; // Prevent looping
    e.currentTarget.src = defaultAvatar;
    e.currentTarget.className = 'avatar-icon';
    e.currentTarget.alt = t('MyProfile.DefaultAvatar');
  };

  if (!settings && loading) {
    return null; // Renders nothing while the initial data is being fetched from server
  }

  // Here the loading has finished, it represents a real failure to load settings
  if (!settings) {
    return (
      <Stack tokens={stackTokens} horizontalAlign='center' className={styles.container}>
        <Text className={styles.title}>{t('MyProfile.MySettings')}</Text>
        <MessageBar messageBarType={MessageBarType.error}>{t('MyProfile.FailedToLoadSettings')}</MessageBar>
      </Stack>
    );
  }

  return (
    <>
      <Stack tokens={stackTokens} horizontalAlign='center' className={styles.container}>
        <Text className={styles.title}>{t('MyProfile.MySettings')}</Text>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel='Close'>
            {error}
          </MessageBar>
        )}
        <div className={styles.row}>
          <span className={styles.label}>{t('MyProfile.userId')}</span>
          <Text className={`${styles.field} ${styles.plainText}`}>{settings.uniqueId}</Text>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{t('MyProfile.loginEmail')}</span>
          <Text className={`${styles.field} ${styles.plainText}`}>{settings.loginId}</Text>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{t('MyProfile.DisplayName')}</span>
          <div className={styles.field}>
            <TextField value={settings?.displayName} onChange={handleDisplayNameChange} styles={inputStyles} />
            <DefaultButton onClick={handleUpdateDisplayName} className={styles.updateButton} disabled={!isModified}>
              {t('Update')}
            </DefaultButton>
          </div>
        </div>
        <div className={styles.row}>
          <span className={styles.label}>{t('MyProfile.DisplayLanguage')}</span>
          <div className={styles.field}>
            <Dropdown
              selectedKey={settings?.displayLanguage}
              onChange={handleLanguageChange}
              options={[
                { key: 'eng', text: 'English' },
                { key: 'chs', text: '简体中文' },
                { key: 'cht', text: '繁體中文' }
              ]}
              styles={dropdownStyles}
            />
          </div>
        </div>

        <div className={styles.row}>
          <span className={styles.label}>{t('MyProfile.Avatar')}</span>
          <div className={styles.field}>
            <div onClick={handleAvatarClick} className={styles.avatarContainer}>
              <Image
                src={settings.avatarUrl}
                alt={t('MyProfile.Avatar')}
                width={74}
                height={74}
                onError={handleAvatarError}
              />
            </div>
          </div>
        </div>
        <input
          type='file'
          ref={fileInputRef}
          onChange={handleFileChange}
          accept='image/*'
          style={{ display: 'none' }}
        />
      </Stack>
      <Footer />
    </>
  );
};
const styles = mergeStyleSets({
  container: {
    maxWidth: '600px',
    margin: '0 auto',
    '@media screen and (max-device-width: 640px)': {
      width: '100%',
      padding: '0 8px',
      boxSizing: 'border-box'
    }
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#666',
    textAlign: 'center',
    marginTop: '16px',
    marginBottom: '16px'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media screen and (max-device-width: 640px)': {
      width: '100%'
    }
  },
  label: {
    width: '40%',
    textAlign: 'right',
    paddingRight: '15px',
    color: '#666',
    fontSize: '14px',
    '@media screen and (max-device-width: 640px)': {
      width: '140px',
      textAlign: 'right',
      paddingRight: '10px'
    }
  },
  field: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media screen and (max-device-width: 640px)': {
      width: '200px'
    }
  },
  plainText: {
    fontSize: '14px',
    padding: '5px 0'
  },
  updateButton: {
    marginLeft: '10px',
    alignSelf: 'center',
    '@media screen and (max-device-width: 640px)': {
      marginLeft: '5px'
    }
  },
  avatarContainer: {
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #d0d0d0',
    boxSizing: 'border-box'
  }
});

const inputStyles: Partial<ITextFieldStyles> = {
  fieldGroup: {
    width: 200,
    maxWidth: '200px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    ':hover': {
      border: '1px solid #e0e0e0'
    },
    '@media screen and (max-device-width: 640px)': {
      width: '120px'
    }
  },
  field: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    backgroundColor: '#f5f5f5',
    padding: '4x 8px',
    ':focus': {
      border: 'none',
      outline: 'none'
    },
    ':focus-within': {
      border: 'none',
      outline: 'none'
    }
  },
  wrapper: {
    ':focus-within': {
      border: '1px solid #0078d4',
      borderRadius: '4px'
    }
  }
};
const dropdownStyles: Partial<IDropdownStyles> = {
  root: {
    width: 200,
    maxWidth: '200px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    '@media screen and (max-device-width: 640px)': {
      width: '120px'
    }
  },
  title: {
    backgroundColor: '#f5f5f5',
    border: 'none',
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    padding: '4px 28px 4px 8px', // Add padding-right for caret
    lineHeight: 'normal',
    position: 'relative', // For absolute positioning of caret
    ':after': {
      content: '""',
      position: 'absolute',
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 0,
      height: 0,
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid black',
      pointerEvents: 'none'
    }
  },
  dropdown: {
    backgroundColor: '#f5f5f5'
  },
  caretDownWrapper: {
    display: 'none' // Hide the default caret
  },
  dropdownOptionText: {
    fontSize: '14px'
  },
  dropdownItems: {
    backgroundColor: '#f5f5f5'
  },
  dropdownItem: {
    backgroundColor: 'transparent',
    borderRadius: '0 !important',
    selectors: {
      '&:hover': {
        backgroundColor: '#d3d3d3 !important',
        borderRadius: '0 !important'
      },
      '&.is-selected': {
        backgroundColor: '#b0b0b0 !important',
        borderRadius: '0 !important'
      },
      '&.ms-Dropdown-item--selected': {
        backgroundColor: '#b0b0b0 !important'
      },
      '&:focus': {
        borderRadius: '0 !important'
      }
    }
  },
  dropdownItemSelected: {
    backgroundColor: '#b0b0b0 !important',
    borderRadius: '0 !important'
  }
};

export default MySettings;
