import React, { useEffect, useState } from 'react';
//import { Image } from '@fluentui/react/lib/Image';
import { _dataProvider } from '../dataProvider/DataProvider';

/*
<img
className='FullscreenBookCover'
key={index}
src={HostOfficialServer + '/lesson/' + book.name + '/cover'}
alt={'' + inView}
onError={(e) => {
  (e.target as HTMLButtonElement).style.visibility = 'hidden';
}}
/>

              <Image
                src={`${HostOfficialServer}/lesson/${course.ClassId}/cover`}
                alt={course.ClassNameChs}
                style={{ width: 140, height: 140 }}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.style.display = 'none';
                  const parentElement = e.currentTarget.parentElement;
                  if (parentElement) {
                    const textElement = document.createElement('div');
                    textElement.textContent = course.ClassNameChs;
                    textElement.style.padding = '10px';
                    parentElement.appendChild(textElement);
                  }
                }}
              />

*/
const BookCoverImage = (props) => {
  const { bookId, bookName, className, style } = props;
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    _dataProvider.getBookCover(bookId).then((url) => setImageUrl(url));
  }, []);

  return (
    <>
      <h2 className='bookNameUnderImage'>{bookName}</h2>
      {imageUrl && (
        <img
          src={imageUrl}
          className={className}
          alt={bookName}
          style={style}
          onError={(e) => {
            e.target.style.visibility = 'hidden';
          }}
        />
      )}
    </>
  );
};

/*
    <Image
      src={imageUrl}
      className={className}
      alt={bookName}
      style={style}
      onError={(e) => {
        e.currentTarget.onerror = null;
        e.currentTarget.style.display = 'none';
        const parentElement = e.currentTarget.parentElement;
        if (parentElement) {
          const textElement = document.createElement('div');
          textElement.textContent = bookName;
          textElement.style.padding = '10px';
          parentElement.appendChild(textElement);
        }
      }}
    />
    */

export default BookCoverImage;
