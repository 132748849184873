// ts-ignore-next-line
import io from 'socket.io-client';
import { cryptoProxy, getToken, SERVER_HOST } from '../SharedCommon/utils';
//import i18n from '../i18n';
//import { _dataProvider } from './DataProvider';
import cryptoUtils from './cryptoUtil';

export default class GTSocket {
  //static
  socket = null;
  id = '';
  useNewFav = false;
  useCryptoProxy = false;
  // eslint-disable-next-line
  newMessageCallback = (data) => {};
  // eslint-disable-next-line
  deleteMessageCallback = (data) => {};
  connectedCallback = () => {};
  disconnectedCallback = () => {};
  reconnectedCallback = () => {};
  groupTagsCallback = () => {};
  newGroupInfoCallback = () => {};

  setCallback(receiveNewMessage: any) {
    this.newMessageCallback = receiveNewMessage;
  }
  constructor({ id, onNewMessage, getTags = true, shareInGroups = '', useNewFav = false }) {
    this.init({ id, onNewMessage, getTags, shareInGroups, useNewFav });
  }

  init({ id, onNewMessage, getTags = true, shareInGroups = '', useNewFav = false }) {
    console.log('init socket for: ', id, getTags, shareInGroups, useNewFav);

    this.newMessageCallback = onNewMessage;
    this.useNewFav = useNewFav;

    if (useNewFav) {
      getTags = false;
    }

    this.closeSocket();
    this.id = id;

    this.useCryptoProxy = window.location.href.indexOf('ppp=1') !== -1;
    const host = this.useCryptoProxy ? cryptoProxy : SERVER_HOST;
    const sss = io(host, {
      transports: ['websocket'], // use WebSocket first, if available
      reconnection: true,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 30000
    });
    console.log('socket created : ', this.socket, sss);
    if (window.___socket) {
      //in strict mode, code are run twice
      //two runs will both queued at io() call
      //when io call returns, it needs to close the last one
      //to avoid side effects of two socket connections
      // kill the last one, let the latest one to win
      window.___socket.close();
      //this.closeSocket();
    }
    window.___socket = sss;
    this.socket = sss;

    this.socket.on('connect', () => {
      console.log(`new socket: [${this.socket.id}]connected!`);
      this.onConnected();
      this.connectedCallback();
    });

    this.socket.on('disconnect', (reason) => {
      console.log(`[${this.socket.id}]disconnect! ${reason}`);
      this.disconnectedCallback();
    });

    this.socket.on('reconnect', (error) => {
      console.log(`[${this.socket.id}]reconnect!`, error);
      this.reconnectedCallback();
    });

    this.socket.on('error', (error) => {
      // no need to show since we will reconnect
      console.log(error);
    });

    this.socket.on('connect_error', (error) => {
      console.log(error);
      // revert back to polling
      this.socket.io.opts.transports = ['polling'];
      this.socket.io.opts.upgrade = false;
    });

    this.socket.on('connect_timeout', (timeout) => {
      // no need to show since we will reconnect
      console.log(`connect_timeout: ${timeout}`);
    });

    this.socket.on('reconnect_error', (error) => {
      console.log(error);
    });

    this.socket.on('reconnect_failed', () => {
      alert('reconnect_failed');
    });

    this.socket.on('newMessages', (data) => {
      console.log('newMessages', this.socket.id, data);
      if (this.useCryptoProxy) {
        data = JSON.parse(cryptoUtils.decryptSession(data));
      }
      this.onNewMessage(data);
    });

    this.socket.on('deleteMessage', (data) => {
      console.log('deleteMessage', data);
      this.deleteMessageCallback(data);
    });

    this.socket.on('groupTags', (data) => {
      console.log('groupTags', data);
      this.groupTagsCallback(data);
    });

    this.socket.on('newGroupInfo', (data) => {
      console.log('newGroupInfo', data);
      this.newGroupInfoCallback(data);
    });

    // load initial messages
    //this.getMessagesAsync({ getTags, shareInGroups });
  }

  onConnected() {
    //TODONOW:
    //*
    if (this.useCryptoProxy) {
      try {
        const data = cryptoUtils.encryptSession({ chatId: this.id, accessToken: getToken() });
        if (!data) {
          console.log('onConnected: invalid1');
          return;
        }
        this.socket.emit('enterRoom', [cryptoUtils.getSessionSecurityToken(), data].join('.'));
      } catch (error) {
        console.log('onConnected: ', error);
        return;
      }
    } else {
      this.socket.emit('enterRoom', {
        chatId: this.id,
        accessToken: getToken()
      });
    }
  }

  onNewMessage(data) {
    try {
      if (!Array.isArray(data)) {
        console.log('Invalid data received from server!', data);
        return;
      }
      data.sort((msg1, msg2) => {
        return msg2.time - msg1.time;
      });
      this.newMessageCallback(data);
    } catch (error) {
      console.log('onNewMessage: ', error, data);
      return;
    }
  }

  /* -- was from iDigestApp -- unused
  async getMessagesAsync({ from = '', to = '', tag = '', shareInGroups = '', search = '', getTags = false }) {
    let result;
    if (this.useNewFav && tag) {
      //result = await globalThis.dsObject.getCollectionCategory(this.id, tag === 'GroupFavorite' ? 'group' : 'user');
    } else {
      let queryString = '';
      //*
        const fromId = messages[messages.length - 1].messageId;
        const data = { from: fromId, search: searchText };  
        <from is the oldest message id === 
          this needed to support scrolling upward, past the current set
          />
        //TODO:    
      //
      if (from) {
        queryString += `&from=${from}`;
      }
      if (to) {
        queryString += `&to=${to}`;
      }
      if (tag) {
        queryString += `&tag=${encodeURIComponent(tag)}`;
      }
      if (getTags) {
        queryString += `&getTags=${getTags}`;
      }
      if (shareInGroups) {
        queryString += `&shareInGroups=${shareInGroups}`;
      }
      if (search) {
        queryString += `&search=${encodeURIComponent(search)}`;
      }
      //result = await globalThis.dsObject.getChatMessage(this.id, queryString.substring(1));
      result = await _dataProvider.getGroupMessage(this.id, queryString.substring(1));
    }

    if (!result) {
      alert(i18n.t('Errors.ErrMsgUpdate'));
      return;
    }

    ///*
    if (Array.isArray(result.body.tags)) {
      this.groupTagsCallback(result.body.tags);
    }
    //

    this.onNewMessage(result);
  }
  */

  async sendMessageAsync(data) {
    console.log(data);
    //const result = await globalThis.dsObject.sendMessage(data);
    //return result ? true : false;
  }

  async deleteMessageAsync(messageId) {
    console.log(messageId);
    //const result = await globalThis.dsObject.deleteChatMessage(this.id, messageId);
    //return result ? true : false;
  }

  closeSocket() {
    if (this.socket) {
      this.socket.close();
    }
    this.socket = null;
  }
}
