import { IBook } from '../dataProvider/IBookTable';
import React from 'react';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { SearchContext } from '../ISearchContextType';
//import { HostOfficialServer } from '../SharedCommon/utils';
import { InView } from 'react-intersection-observer';
import BookCoverImage from '../SharedCommon/BookCoverImage';

interface IDiscoverBookProps {
  books: IBook[];
  setBook: any; // (any, boolean): void;

  signInState: boolean;
  contentHeight: string;
}

interface IDiscoverBookState {
  books: IBook[][];
  bookCategories: string[];
}

class DiscoverBook extends React.Component<IDiscoverBookProps, IDiscoverBookState> {
  constructor(props: IDiscoverBookProps) {
    super(props);
    this.state = {
      books: [
        props.books.filter((it) => !!it.isMyClass),
        props.books
          .slice()
          .sort((a, b) => b.classId - a.classId)
          .slice(0, 6),
        props.books
      ],
      bookCategories: this.getBookCategories()
    };
  }

  getBookCategories = (): string[] => [i18n.t('MyStudy'), i18n.t('Latest'), i18n.t('Popular')];

  static contextType = SearchContext;
  context!: React.ContextType<typeof SearchContext>;

  render(): React.ReactNode {
    const { searchQuery } = this.context;
    const { bookCategories } = this.state;

    return (
      <div className='HomeMatrixBooks' style={{ height: this.props.contentHeight }}>
        {bookCategories.map((category, idx) => {
          if (idx === 0 && !this.props.signInState) {
            return null;
          }

          const filteredBooks = this.state.books[idx].filter(
            (book) =>
              searchQuery.trim() === '' ||
              book.classNameChs?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              book.classNameCht?.toLowerCase().includes(searchQuery.toLowerCase()) ||
              book.classNameEng?.toLowerCase().includes(searchQuery.toLowerCase())
          );

          return (
            <div key={idx}>
              <div key={`category${idx}`} className='bookCategoryHeader'>
                <label style={{ color: 'black' }}>
                  <button className='bookCategoryMark' />
                  {category}
                </label>
              </div>
              <div key={`content${idx}`} className='ContentV' style={{ height: 'auto' }}>
                {filteredBooks.map((book, index) => (
                  <InView key={index * 1000}>
                    {({ inView, ref /*, entry*/ }) => (
                      <div
                        ref={ref}
                        key={index}
                        className='FullscreenBookCoverDiv'
                        onClick={() => {
                          const bookIdx = this.props.books.findIndex((bk) => bk === book);
                          this.props.setBook(bookIdx);
                        }}>
                        {inView && (
                          <BookCoverImage
                            className='FullscreenBookCover'
                            bookId={book.name}
                            bookName={book.classNameChs}
                          />
                        )}
                      </div>
                    )}
                  </InView>
                ))}
              </div>
              <br />
            </div>
          );
        })}
        <Footer />
      </div>
    );
  }

  public componentDidMount(): void {
    console.log('DiscoverBook componentDidMount');
    i18n.on('languageChanged', this.handleLanguageChange);
  }

  componentWillUnmount() {
    i18n.off('languageChanged', this.handleLanguageChange);
  }

  handleLanguageChange = () => {
    this.setState({ bookCategories: this.getBookCategories() });
  };

  public shouldComponentUpdate(nextProps: IDiscoverBookProps) {
    console.log('DiscoverBook shouldComponentUpdate');
    if (nextProps !== this.props) {
      this.setState({
        books: [
          nextProps.books.filter((it) => !!it.isMyClass),
          nextProps.books
            .slice()
            .sort((a, b) => b.classId - a.classId)
            .slice(0, 6),
          nextProps.books
        ]
      });
    }
    return true;
  }
}

export default DiscoverBook;
