/* eslint no-use-before-define: ["error", { "variables": false }] */

import { StyleSheet, Text, View } from 'react-native-web';

import Color from './Color';
import PropTypes from 'prop-types';
import React from 'react';
import TouchableOpacity from './TouchableOpacity';

export default function Send({ text, containerStyle, onSend, children, textStyle, label, alwaysShowSend }) {
  if (alwaysShowSend || text.trim().length > 0) {
    return (
      <TouchableOpacity
        testID='send'
        accessible
        accessibilityLabel='send'
        style={{ ...styles.container, ...containerStyle }}
        onPress={() => {
          console.log('asdasdasd');
          onSend({ text: text.trim() }, true);
        }}
        accessibilityTraits='button'>
        <View>{children || <Text style={[styles.text, textStyle]}>{label}</Text>}</View>
      </TouchableOpacity>
    );
  }
  return <View />;
}

const styles = StyleSheet.create({
  container: {
    height: 44,
    justifyContent: 'flex-end'
  },
  text: {
    color: Color.defaultBlue,
    fontWeight: '600',
    fontSize: 17,
    backgroundColor: Color.backgroundTransparent,
    marginBottom: 12,
    marginLeft: 10,
    marginRight: 10
  }
});

Send.defaultProps = {
  text: '',
  onSend: () => {},
  label: 'Send',
  containerStyle: {},
  textStyle: {},
  children: null,
  alwaysShowSend: false
};

Send.propTypes = {
  text: PropTypes.string,
  onSend: PropTypes.func,
  label: PropTypes.string,
  //containerStyle: ViewPropTypes.style,
  //textStyle: Text.propTypes.style,
  children: PropTypes.element,
  alwaysShowSend: PropTypes.bool
};
