import axios from 'axios';
import { wipeSignInState, getAuthStr, cryptoProxy } from '../SharedCommon/utils';
import cryptoUtils from './cryptoUtil';

function createAxiosInstance(baseURL: string, getLocale: () => string) {
  //TODO: while in development ==
  const useCryptoProxy = window.location.href.indexOf('ppp=1') !== -1;
  const pwdAPIs = ['/user/login', '/user/create'];
  const freeAPIs = ['/home', '/crash'];
  //if (useCryptoProxy) {
  console.log('useCryptoProxy:', useCryptoProxy);
  //baseURL = cryptoProxy;
  //}

  const axiosInstance = axios.create({
    baseURL
    //timeout: 1000,
    //headers: {'X-Custom-Header': 'foobar'}
  });

  // set up request header configurations
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers = {
        lang: getLocale(), // use callback here to get the most updated language option
        'cache-control': 'no-cache'
      };

      const isFormPost = config.data instanceof FormData;

      if (!useCryptoProxy || isFormPost) {
        const authToken = getAuthStr();
        if (authToken) {
          config.headers['Authorization'] = authToken;
        }
        return config;
      }

      // /user/login and /user/create
      if (pwdAPIs.indexOf(config.url ?? '/') !== -1) {
        const reqData = { ...config.data };
        console.log('useCryptoProxy: ', config);
        if (typeof reqData !== 'object') {
          console.log('reqData not object: ', reqData);
          //reqData = JSON.parse(reqData);
        }
        reqData.timestamp = Date.now() / 1000;
        console.log('reqData object: ', reqData);

        config.baseURL = cryptoProxy;
        const req = {
          method: config.method ?? 'GET',
          path: config.url,
          body: ''
        };
        config.transformRequest = [
          function (rawData, headers) {
            console.log('rowData: ', rawData);
            req.body = reqData;
            const data = cryptoUtils.encryptPublic(JSON.stringify(req));
            const buf = JSON.stringify({ data });
            headers['content-length'] = buf.length;
            headers['content-type'] = 'application/json; charset=utf-8';
            return buf;
            //return { data };
          }
        ];
        config.transformResponse = [
          function (data) {
            console.log('transformResponse:', reqData, data);
            let buf = cryptoUtils.decryptPwd(reqData.loginId, reqData.password, data);
            if (typeof buf !== 'object') {
              buf = JSON.parse(buf);
            }
            return buf;
          }
        ];
        return config;
      }

      const token = cryptoUtils.getSessionSecurityToken();

      //free path
      if (!token) {
        if (freeAPIs.indexOf(config.url ?? '/') !== -1) {
          return config;
        }
        // some lesson content is for public
        if (config.url?.startsWith('/lesson')) {
          return config;
        }
        //since there is no session key, the below crypto won't work, error out
        throw new Error('require authentication');
      }

      //all other paths
      config.headers['Authorization'] = 'Bearer ' + token;

      const req: any = {
        method: config.method ?? 'GET',
        path: config.url,
        accessToken: cryptoUtils.getAccessToken(),
        body: ''
      };
      if (config.responseType) {
        req.responseType = config.responseType;

        // remove the type, redo it later
        //config.responseType = undefined;
      }
      config.baseURL = cryptoProxy;
      config.method = 'POST'; // it's always post to the cryptoproxy

      config.transformRequest = [
        function (rawData, headers) {
          req.body = rawData;
          const data = cryptoUtils.encryptSession(JSON.stringify(req));
          const buf = JSON.stringify({ data });
          //headers['content-length'] = buf.length;
          headers['content-type'] = 'application/json; charset=utf-8';
          return buf;
          //return { data };
        }
      ];
      config.transformResponse = [
        function (data) {
          if (req.responseType === 'blob') {
            console.log('transformResponse:', data.size, data);
            //const buf = cryptoUtils.decryptSession(data, false);
            //console.log('transformResponse2:', buf.length, buf);
            //return new Blob([buf], { type: 'application/octet-stream' });
            return data;
          }
          let buf = cryptoUtils.decryptSession(data, true);
          if (typeof buf !== 'object') {
            buf = JSON.parse(buf);
          }
          return buf;
        }
      ];
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // handle error msg 401 on response
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log('customAxios Error: ' + error.response?.status);
      if (error.response?.status === 401) {
        wipeSignInState();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
}

export default createAxiosInstance;
